import React from 'react';
import { graphql } from 'gatsby';
import Layout3 from '../components/layout';
// import Styles from "./mdlist.module.css";
import { dispanel1, linkdiv } from "./mdlist.module.css";



const HomePage = (props: any) => {
  const { data } = props;


  // const testdata = data.allMarkdownRemark.edges.map(({ node }, index) => (
  //   <span key={index}>{node.frontmatter.no}{node.frontmatter.title}{node.frontmatter.sdgs_no}
  //   </span>
  //   ))




    // const csvdata = data.allMarkdownRemark.edges.map(({ node }, index) => (
    //   <span key={index}>{node.frontmatter.no},{node.frontmatter.title},{node.frontmatter.sdgs_no},{node.frontmatter.sdgs_main},{node.frontmatter.sdgs_subcat},{node.frontmatter.kou_tisou_pt},{node.frontmatter.kou_tisou_chp},{node.frontmatter.kou_tisou_sec},{node.frontmatter.kou_tisou_sbsec},{node.frontmatter.kou_tisou_title},{node.frontmatter.kou_titan_pt},{node.frontmatter.kou_titan_chp},{node.frontmatter.kou_titan_sec},{node.frontmatter.kou_titan_sbsec},{node.frontmatter.kou_titan_title},{node.frontmatter.height_name},{node.frontmatter.height_origin},{node.frontmatter.height_origin_note},{node.frontmatter.height_origin_url},{node.frontmatter.height_origin_url2},{node.frontmatter.color_name},{node.frontmatter.color_origin},{node.frontmatter.color_origin_note},{node.frontmatter.color_origin_url},{node.frontmatter.color_origin_url2},{node.frontmatter.sokuchi},{node.frontmatter.mesh_size},{node.frontmatter.year_display},{node.frontmatter.honbun},{node.frontmatter.chyusyaku},{node.frontmatter.hosoku},{node.frontmatter.tags},{node.frontmatter.kml_file},{node.frontmatter.y202012},{node.frontmatter.y202101},{node.frontmatter.y202102},{node.frontmatter.date},{node.frontmatter.description}\n
      
    //   </span>
    //   ))

    // const str = data.allMarkdownRemark.edges[0].node.frontmatter.title
    // console.log(data.allMarkdownRemark.edges);


  return (
    <Layout3>
        <div style={{margin: "30px"}}>
        <h1>開発協力者様向けページ（このサイトに登録されている全てのコンテンツ一覧）</h1>
        <p>　</p>
        <p>サイト構築へのご協力ありがとうございます。</p>

        <h2>このページの概要</h2>
        <p>サイト内から情報を抽出し、全ての情報が一覧形式で整理されています。<br />各種データ作成や、校正作業などに活用いただけます。</p>

        <h2>このページの使い方</h2>
        <p>下記のデータ更新方法をご確認ください。<br /></p>

        <div>
          <div className={dispanel1}>
            <h3>データ更新方法１：一覧表での作業（エクセルにコピペ＞修正＞送信）</h3>
            <p>各表はエクセルに貼り付けることができます。<br />各表のタイトルごとコピーしてエクセルに貼り付けて編集し、事務局宛メールしてください。<br /><span style={{color: "red"}}>一覧形式でざっとチェック＆修正するときにおすすめです。</span></p>
          </div>
          <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/m1PbRqdfS3g" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <p>　</p>

        </div>
        <div>
          <div className={dispanel1}>
            <h3>データ更新方法２：ページごとの作業（テキストをダウンロード＞修正＞送信）</h3>
            <p>表の右端から、各ページのテキストファイルをダウンロード可能です。<br />各ページの情報の全てがページごとに格納されています。<br />修正されたら、事務局宛メールしてください。<br /><span style={{color: "red"}}>各ページごとに、じっくり執筆＆確認をするときにおすすめです。</span></p>
          </div>
          <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/e9SIAcEz1yg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  </div>

        </div>

        <p>　</p>
        <p>　</p>

        <h1>各表へのリンク</h1>
        <h2><a href="#basic">基本情報</a></h2>
        <h2><a href="#sdgs">SDGs</a></h2>
        <h2><a href="#lecture">教科書の目次</a></h2>
        <h2><a href="#origin_h">出典（高さ）</a></h2>
        <h2><a href="#origin_c">出典（色）</a></h2>
        <h2><a href="#honbun">本文</a></h2>
        <h2><a href="#tags">タグ</a></h2>
        <h2><a href="#hanrei">凡例</a></h2>
        <h2><a href="#all">全体</a></h2>

        <div id="basic" className={linkdiv}></div>
        <h2>基本情報</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>height_name</th>
              <th>color_name</th>
              <th>year_display</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.height_name}</td>
                <td>{node.frontmatter.color_name}</td>
                <td>{node.frontmatter.year_display}</td>
                <td>{node.frontmatter.tags}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div id="sdgs" className={linkdiv}></div>

        <h2>SDGs</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>sdgs_no</th>
              <th>sdgs_main</th>
              <th>sdgs_subcat</th>
              <th>height_name</th>
              <th>color_name</th>
              <th>year_display</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.sdgs_no}</td>
                <td>{node.frontmatter.sdgs_main}</td>
                <td>{node.frontmatter.sdgs_subcat}</td>
                <td>{node.frontmatter.height_name}</td>
                <td>{node.frontmatter.color_name}</td>
                <td>{node.frontmatter.year_display}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>


        <div id="lecture" className={linkdiv}></div>
        <h2>教科書の目次</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_tisou_pt</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_tisou_chp</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_tisou_sec</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_tisou_sbsec</th>
              <th>kou_tisou_title</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_titan_pt</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_titan_chp</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_titan_sec</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_titan_sbsec</th>
              <th>kou_titan_title</th>
              <th>height_name</th>
              <th>color_name</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.kou_tisou_pt}</td>
                <td>{node.frontmatter.kou_tisou_chp}</td>
                <td>{node.frontmatter.kou_tisou_sec}</td>
                <td>{node.frontmatter.kou_tisou_sbsec}</td>
                <td>{node.frontmatter.kou_tisou_title}</td>
                <td>{node.frontmatter.kou_titan_pt}</td>
                <td>{node.frontmatter.kou_titan_chp}</td>
                <td>{node.frontmatter.kou_titan_sec}</td>
                <td>{node.frontmatter.kou_titan_sbsec}</td>
                <td>{node.frontmatter.kou_titan_title}</td>
                <td>{node.frontmatter.height_name}</td>
                <td>{node.frontmatter.color_name}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>




        <div id="origin_h" className={linkdiv}></div>
        <h2>出典（高さ）</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>height_name</th>
              <th>height_origin</th>
              <th>height_origin_note</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>height_origin_url_name</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>height_origin_url</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>height_origin_url2_name</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>height_origin_url2</th>
              <th>sokuchi</th>
              <th>mesh_size</th>
              <th>year_display</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.height_name}</td>
                <td>{node.frontmatter.height_origin}</td>
                <td>{node.frontmatter.height_origin_note}</td>
                <td>{node.frontmatter.height_origin_url_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.height_origin_url}</td>
                <td>{node.frontmatter.height_origin_url2_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.height_origin_url2}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.sokuchi}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.mesh_size}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.year_display}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div id="origin_c" className={linkdiv}></div>
        <h2>出典（色）</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>color_name</th>
              <th>color_origin</th>
              <th>color_origin_note</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>color_origin_url_name</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>color_origin_url2_name</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>color_origin_url</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>color_origin_url2</th>
              <th>sokuchi</th>
              <th>mesh_size</th>
              <th>year_display</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.color_name}</td>
                <td>{node.frontmatter.color_origin}</td>
                <td>{node.frontmatter.color_origin_note}</td>
                <td>{node.frontmatter.color_origin_url_name}</td>
                <td>{node.frontmatter.color_origin_url2_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.color_origin_url}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.color_origin_url2}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.sokuchi}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.mesh_size}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.year_display}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>


        <div id="honbun" className={linkdiv}></div>
        <h2>本文</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>honbun</th>
              <th>chyusyaku</th>
              <th>hosoku</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.honbun}</td>
                <td>{node.frontmatter.chyusyaku}</td>
                <td>{node.frontmatter.hosoku}</td>
                <td>{node.frontmatter.tags}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>



        <div id="tags" className={linkdiv}></div>
        <h2>タグリスト</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.tags[0]}</td>
                <td>{node.frontmatter.tags[1]}</td>
                <td>{node.frontmatter.tags[2]}</td>
                <td>{node.frontmatter.tags[3]}</td>
                <td>{node.frontmatter.tags[4]}</td>
                <td>{node.frontmatter.tags[5]}</td>
                <td>{node.frontmatter.tags[6]}</td>
                <td>{node.frontmatter.tags[7]}</td>
                <td>{node.frontmatter.tags[8]}</td>
                <td>{node.frontmatter.tags[9]}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div id="tags" className={linkdiv}></div>
        <h2>タグチェック（分類）</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>sdgs_no</th>
              <th>tags</th>
              <th>kou_tisou_title</th>
              <th>tags</th>
              <th>kou_titan_title</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.sdgs_no}</td>
                <td>{node.frontmatter.tags[0]}</td>
                <td>{node.frontmatter.kou_tisou_title}</td>
                <td>{node.frontmatter.tags[1]}</td>
                <td>{node.frontmatter.kou_titan_title}</td>
                <td>{node.frontmatter.tags[2]}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div id="tags" className={linkdiv}></div>
        <h2>タグチェック（出典）</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>height_name</th>
              <th>tags</th>
              <th>color_name</th>
              <th>tags</th>
              <th>height_origin</th>
              <th>tags</th>
              <th>color_origin</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.height_name}</td>
                <td>{node.frontmatter.tags[3]}</td>
                <td>{node.frontmatter.color_name}</td>
                <td>{node.frontmatter.tags[4]}</td>
                <td>{node.frontmatter.height_origin}</td>
                <td>{node.frontmatter.tags[5]}</td>
                <td>{node.frontmatter.color_origin}</td>
                <td>{node.frontmatter.tags[6]}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>



        <div id="hanrei" className={linkdiv}></div>
        <h2>凡例</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>height_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>color_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>year_display</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei1_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei1_color</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei2_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei2_color</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei3_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei3_color</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei4_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei4_color</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>height_ratio</th>
              <th>txt</th>

            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.height_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.color_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.year_display}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei1_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei1_color}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei2_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei2_color}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei3_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei3_color}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei4_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei4_color}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.height_ratio}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>

              </tr>
            ))}
          </tbody>
        </table>

        <div id="all" className={linkdiv}></div>
        <h2>全体</h2>
        <table>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>sdgs_no</th>
              <th>sdgs_main</th>
              <th>sdgs_subcat</th>
              <th>kou_tisou_pt</th>
              <th>kou_tisou_chp</th>
              <th>kou_tisou_sec</th>
              <th>kou_tisou_sbsec</th>
              <th>kou_tisou_title</th>
              <th>kou_titan_pt</th>
              <th>kou_titan_chp</th>
              <th>kou_titan_sec</th>
              <th>kou_titan_sbsec</th>
              <th>kou_titan_title</th>
              <th>height_name</th>
              <th>height_origin</th>
              <th>height_origin_note</th>
              <th>height_origin_url_name</th>
              <th>height_origin_url2_name</th>
              <th>height_origin_url</th>
              <th>height_origin_url2</th>
              <th>color_name</th>
              <th>color_origin</th>
              <th>color_origin_note</th>
              <th>color_origin_url_name</th>
              <th>color_origin_url2_name</th>
              <th>color_origin_url</th>
              <th>color_origin_url2</th>
              <th>sokuchi</th>
              <th>mesh_size</th>
              <th>year_display</th>
              <th>honbun</th>
              <th>chyusyaku</th>
              <th>hosoku</th>
              <th>tags</th>
              <th>kml_file</th>
              <th>y202012</th>
              <th>y202101</th>
              <th>y202102</th>
              <th>date</th>
              <th>description</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.sdgs_no}</td>
                <td>{node.frontmatter.sdgs_main}</td>
                <td>{node.frontmatter.sdgs_subcat}</td>
                <td>{node.frontmatter.kou_tisou_pt}</td>
                <td>{node.frontmatter.kou_tisou_chp}</td>
                <td>{node.frontmatter.kou_tisou_sec}</td>
                <td>{node.frontmatter.kou_tisou_sbsec}</td>
                <td>{node.frontmatter.kou_tisou_title}</td>
                <td>{node.frontmatter.kou_titan_pt}</td>
                <td>{node.frontmatter.kou_titan_chp}</td>
                <td>{node.frontmatter.kou_titan_sec}</td>
                <td>{node.frontmatter.kou_titan_sbsec}</td>
                <td>{node.frontmatter.kou_titan_title}</td>
                <td>{node.frontmatter.height_name}</td>
                <td>{node.frontmatter.height_origin}</td>
                <td>{node.frontmatter.height_origin_note}</td>
                <td>{node.frontmatter.height_origin_url_name}</td>
                <td>{node.frontmatter.height_origin_url2_name}</td>
                <td>{node.frontmatter.height_origin_url}</td>
                <td>{node.frontmatter.height_origin_url2}</td>
                <td>{node.frontmatter.color_name}</td>
                <td>{node.frontmatter.color_origin}</td>
                <td>{node.frontmatter.color_origin_note}</td>
                <td>{node.frontmatter.color_origin_url_name}</td>
                <td>{node.frontmatter.color_origin_url2_name}</td>
                <td>{node.frontmatter.color_origin_url}</td>
                <td>{node.frontmatter.color_origin_url2}</td>
                <td>{node.frontmatter.sokuchi}</td>
                <td>{node.frontmatter.mesh_size}</td>
                <td>{node.frontmatter.year_display}</td>
                <td>{node.frontmatter.honbun}</td>
                <td>{node.frontmatter.chyusyaku}</td>
                <td>{node.frontmatter.hosoku}</td>
                <td>{node.frontmatter.tags}</td>
                <td>{node.frontmatter.kml_file}</td>
                <td>{node.frontmatter.y202012}</td>
                <td>{node.frontmatter.y202101}</td>
                <td>{node.frontmatter.y202102}</td>
                <td>{node.frontmatter.date}</td>
                <td>{node.frontmatter.description}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </Layout3>
  );
};

export default HomePage;

interface IndexPageProps {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: {
              title: string;
              no: string;
              sdgs_no: string;
              sdgs_main: string;
              sdgs_subcat: string;
              kou_tisou_pt: string;
              kou_tisou_chp: string;
              kou_tisou_sec: string;
              kou_tisou_sbsec: string;
              kou_tisou_title: string;
              kou_titan_pt: string;
              kou_titan_chp: string;
              kou_titan_sec: string;
              kou_titan_sbsec: string;
              kou_titan_title: string;
              height_name: string;
              height_origin: string;
              height_origin_note: string;
              height_origin_url: string;
              height_origin_url_name: string;
              height_origin_url2: string;
              height_origin_url2_name: string;
              color_name: string;
              color_origin: string;
              color_origin_note: string;
              color_origin_url: string;
              color_origin_url2: string;
              color_origin_url_name: string;
              color_origin_url2_name: string;
              sokuchi: string;
              mesh_size: string;
              year_display: string;
              honbun: string;
              chyusyaku: string;
              hosoku: string;
              tags: string;
              kml_file: string;
              y202012: string;
              y202101: string;
              y202102: string;
              date: string;
              description: string;
            }
          }
        }
      ]
    }
  };
}



declare function graphql(x: TemplateStringsArray): any;
export const pageQuery = graphql`
query {
  allMarkdownRemark(sort: {fields: frontmatter___no}) {
    edges {
      node {
        frontmatter {
          title
          no
          sdgs_no
          sdgs_main
          sdgs_subcat
          kou_tisou_pt
          kou_tisou_chp
          kou_tisou_sec
          kou_tisou_sbsec
          kou_tisou_title
          kou_titan_pt
          kou_titan_chp
          kou_titan_sec
          kou_titan_sbsec
          kou_titan_title
          height_name
          height_origin
          height_origin_note
          height_origin_url_name
          height_origin_url2_name
          height_origin_url
          height_origin_url2
          color_name
          color_origin
          color_origin_note
          color_origin_url_name
          color_origin_url2_name
          color_origin_url
          color_origin_url2
          sokuchi
          mesh_size
          year_display
          honbun
          chyusyaku
          hosoku
          tags
          kml_file
          y202012
          y202101
          y202102
          date
          description
          hanrei1_name
          hanrei1_color
          hanrei2_name
          hanrei2_color
          hanrei3_name
          hanrei3_color
          hanrei4_name
          hanrei4_color
          height_ratio
        }
      }
    }
  }
}

`;
